import React from "react";
import HeaderBar from "./components/HeaderBar";
import TitleSection from "./components/TitleSection";
import SectionContainer from "./components/SectionContainer";
import Footer from "./components/Footer";

function App() {
    return (
        <>
            <HeaderBar />
            <TitleSection />
            <SectionContainer />
            <Footer />
        </>
    );
}

export default App;
