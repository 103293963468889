import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Copyright = styled.p`
    padding-bottom: 30px;
`;

export default function Footer() {
    const year = new Date().getFullYear();

    return (
        <StyledFooter>
            <Copyright>© Copyright {year} Erik Lysne</Copyright>
        </StyledFooter>
    );
}
