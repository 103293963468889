import iconAtmel from "./images/Atmel.png";
import iconSTM from "./images/STM.png";
import iconARM from "./images/ARM.png";
import iconAltera from "./images/Altera.png";
import iconVerilog from "./images/Verilog.png";
import iconEagle from "./images/Eagle.jpg";
import iconFusion360 from "./images/Fusion360.png";
import iconRaspberryPi from "./images/RaspberryPi.png";
import iconArduino from "./images/Arduino.png";

export default [
    {
        label: "Atmel microcontrollers",
        icon: iconAtmel
    },
    {
        label: "STM microcontrollers",
        icon: iconSTM
    },
    {
        label: "ARM Cortex-M",
        icon: iconARM
    },
    {
        label: "Altera FPGA",
        icon: iconAltera
    },
    {
        label: "Verilog HDL",
        icon: iconVerilog
    },
    {
        label: "Autodesk Eagle",
        icon: iconEagle
    },
    {
        label: "Autodesk Fusion 360",
        icon: iconFusion360
    },
    {
        label: "Raspberry Pi",
        icon: iconRaspberryPi
    },
    {
        label: "Arduino",
        icon: iconArduino
    },
    {
        label: "Digital circuit design"
    },
    {
        label: "Analog circuit design"
    },
    {
        label: "PCB design"
    },
    {
        label: "3D printing"
    }
];
