import React from "react";
import styled from "styled-components";

const Entry = styled.div`
    width: 90%;
    padding: 20px;
`;

const Title = styled.div`
    padding: 5px 0;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    color: #67c1ff;
`;

const Institute = styled.em``;

const Description = styled.p``;

const Thesis = styled.a`
    color: #eeeeee;
`;

export default function EducationSection() {
    const entries = [
        {
            duration: "2018-2021",
            title: "Philosophiae Doctor (PhD), Materials Science",
            institute: "Norwegian University of Science and Technology (NTNU)",
            description:
                "PhD in materials science. Research on functional topological systems, specializing in low-dimensional magnetic textures and topological defects.",
            thesis: "TBA"
        },
        {
            duration: "2012-2018",
            title: "MSc. Electrical Engineering, Nanoelectronics and Photonics",
            institute: "Norwegian University of Science and Technology (NTNU)",
            description:
                "5 year MSc program in electrical engineering. After completing the first two years, I specialized in nanoelectronics and photonics, with particular focus on photonics. For my thesis project, I designed an optical spectroscopy system based on ultrafast, nonlinear optics and magneto optics.",
            thesis:
                "Ultrafast Optical and Magneto-Optical Kerr Effect Spectroscopy on Condensed Matter",
            thesisRef:
                "https://ntnuopen.ntnu.no/ntnu-xmlui/handle/11250/2467024"
        },
        {
            duration: "2011-2012",
            title: "BSc. Electrical Engineering and Computer Science",
            institute:
                "Formerly Høgskolen i Sør-Trøndelag (HiST), now part of NTNU",
            description:
                "First year of a BSc degree in electrical engineering and computer science. After completing the first year, I transferred to NTNU to attend a 5 year MSc. degree."
        }
    ];
    return (
        <>
            {entries.map((entry) => (
                <Entry>
                    <Title>
                        <b>{entry.duration}</b> - {entry.title}
                    </Title>
                    <Institute>{entry.institute}</Institute>
                    <Description>{entry.description}</Description>
                    {entry.thesis && (
                        <>
                            <b>Thesis: </b>
                            <Thesis href={entry.thesisRef}>
                                {entry.thesis}
                            </Thesis>
                        </>
                    )}
                </Entry>
            ))}
        </>
    );
}
