import React from "react";
import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import iconGitHub from "./images/GitHub-Mark-Light-32px.png";

const StyledNavbar = styled(Navbar)`
    width: 100%;
    height: 32px;
    padding: 24px 32px;
    position: fixed;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
`;

const StyledNav = styled(Nav)``;

const GitHubLink = styled(Nav.Link)`
    width: 32px;
    height: 32px;
    background-image: url(${iconGitHub});
`;

export default function HeaderBar() {
    return (
        <>
            <StyledNavbar variant="dark">
                <StyledNav className="ml-auto">
                    <GitHubLink href="https://github.com/ErikLysne"></GitHubLink>
                </StyledNav>
            </StyledNavbar>
        </>
    );
}
