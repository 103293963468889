import iconC from "./images/C.png";
import iconCpp from "./images/C++.png";
import iconQt from "./images/Qt.png";
import iconHTML5 from "./images/HTML5.png";
import iconCSS3 from "./images/CSS3.png";
import iconJS from "./images/JS.png";
import iconBootstrap from "./images/Bootstrap.png";
import iconReact from "./images/React.png";
import iconNode from "./images/Node.png";
import iconExpress from "./images/Expressjs.png";
import iconMongoose from "./images/mongoose.png";
import iconRedux from "./images/Redux.png";
import iconjQuery from "./images/jQuery.png";
import iconEJS from "./images/EJS.png";
import iconJest from "./images/Jest.png";
import iconFlow from "./images/Flow.png";
import iconMatlab from "./images/Matlab.png";

export default [
    {
        label: "C++",
        icon: iconCpp
    },
    {
        label: "C",
        icon: iconC
    },
    {
        label: "Qt",
        icon: iconQt
    },
    {
        label: "QML",
        icon: iconQt
    },
    {
        label: "HTML 5*",
        icon: iconHTML5
    },
    {
        label: "CSS 3*",
        icon: iconCSS3
    },
    {
        label: "JavaScript ES6*",
        icon: iconJS
    },
    {
        label: "Bootstrap*",
        icon: iconBootstrap
    },
    {
        label: "React.JS*",
        icon: iconReact
    },
    {
        label: "Node.JS*",
        icon: iconNode
    },
    {
        label: "Express.JS*",
        icon: iconExpress
    },
    {
        label: "Mongoose",
        icon: iconMongoose
    },
    {
        label: "Redux",
        icon: iconRedux
    },
    {
        label: "jQuery",
        icon: iconjQuery
    },
    {
        label: "EJS",
        icon: iconEJS
    },
    {
        label: "Jest",
        icon: iconJest
    },
    {
        label: "Flow",
        icon: iconFlow
    },
    {
        label: "MATLAB",
        icon: iconMatlab
    }
];
