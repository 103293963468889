import React from "react";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";

const StyledNav = styled(Nav)`
    width: 100%;
    border: none;
    background-color: transparent !important;
`;

const StyledNavItem = styled(Nav.Item)`
    position: relative;
    display: inline-block;

    @media only screen and (max-width: 768px) {
        font-size: 0.75rem;
    }
`;

const StyledNavLink = styled(Nav.Link)`
    z-index: ${(props) => (props.isActive ? 2 : 1)} !important;
    border-style: ${(props) =>
        props.isActive
            ? "solid solid none solid"
            : "none none solid none"} !important;
    border-width: 4px !important;
    border-color: rgb(16, 16, 33) !important;
    color: ${(props) => (props.isActive ? "#eeeeee" : "#458bff")} !important;
    background-color: rgba(6, 6, 6, 0.75) !important;
    backdrop-filter: blur(3px) !important;

    &:hover {
        background-color: rgba(26, 26, 26, 0.75) !important;
    }
`;

export default function TabBar({ tabs, activeTab, handleSelect }) {
    return (
        <StyledNav fill variant="tabs" onSelect={handleSelect}>
            {tabs.map((tab, index) => (
                <StyledNavItem key={index}>
                    <StyledNavLink
                        eventKey={tab.key}
                        isActive={activeTab === tab.key}
                    >
                        {tab.label}
                    </StyledNavLink>
                </StyledNavItem>
            ))}
        </StyledNav>
    );
}
