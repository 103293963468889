export default [
    {
        label: "Atomic force microscopy (AFM)",
        brief:
            "An atomic force microscope is an imaging system that utilizes the force between the surface of a specimen and a very sharp needle attached to a cantilever to create an image of the specimen topography. AFM tips can be manifactured to have a radius of only a few nanometers, and can therefore detect extremely localized forces. The type of forces detected by an atomic force microscope include attractive van der Waals forces, chemical bonding, repulsive Pauli forces, electrostatic and magnetostatic forces."
    },
    {
        label: "Scanning probe microscopy (SPM)",
        brief:
            "Atomic force microscopy can be generalized to create surface maps beyond just topography. By functionalizing the AFM tip, a large variety of properties can be imaged with very high resolution. SPM includes techniques such as magnetic force microscopy (MFM) electrostatic force microscopy (EFM), scanning Kelvin probe force microscopy (SKPFM), conductive atomic force microscopy (cAFM) and piezoelectric force microscopy (PFM) to name a few."
    },
    {
        label: "Scanning electron microscopy (SEM)",
        brief:
            "A normal optical microscope works by illuminating a specimen with light and observing the light reflected from the specimen, or transmitted through the specimen if it is backlit. In electron microscopy, the specimen is instead illuminated by an electron beam, and the electrons scattered or emitted by the specimen are collected by an electron detector. In scanning electron microscopy, the specimen is scanned by the electron beam to create an image of the surface, and a resolution below one nanometer can be achieved."
    },
    {
        label: "Focused ion beam (FIB)",
        brief:
            "A focused ion beam system operates on the same principle as SEM, however an ion beam is used instead of the electron beam to illuminate the specimen. Ions are much heavier than electrons and can be destructve to the surface. The FIB can therefore be utilized as a nano-machining tool, allowing structural milling with nanometer accuracy. FIB can also be used to deposit materials with very high accuracy, by pumping chemical vapor into the FIB chamber, where a decomposition process can be activated by the ion beam, extracting non-volatile components from the gas to form a deposition layer."
    },
    {
        label: "Photolithography",
        brief:
            "Photolithography is the pilar of the semiconductor manufacturing industry. It is a process in which a geometric pattern is transferred to a wafer. The process starts by coating the surface of a wafer, typically made from silicon, with a photosensitive film called a photoresist. A geometric pattern is then transferred to the photoresist by exposing parts of the photoresist to ultraviolet light through a mask, or by using a scanning laser system or similar schemes. Finally, the resist can be developed by etching parts of the photoresist illuminated in the previous step, leaving the rest in tact, or vice versa. This leaves a 2D mask on the surface which can be used for metallization, doping or other processing. Repeating this process layer by layer, a silicon wafer can be made into a complex integrated circuit."
    },
    {
        label: "Sputtering",
        brief: ""
    },
    {
        label: "E-beam evaporation",
        brief: ""
    },
    {
        label: "Wire bonding",
        brief: ""
    },
    {
        label: "Micromagnetic simulations",
        brief:
            "The internal structure of magnets is generally complex, and depends on factors like exchange forces, geometric shape, crystal structure, external magnetic fields, temperature and electrical currents. Micromagnetic simulators are indisposable in the study of micro- and nanoscale magnetism. The micromagnetic regime spans length scales suficiently large to ignore atomic fluctuations, but small enough to reveal the complex internal structure of magnetic objects such as domain walls, defects and magnetic skyrmions. Micromagnetic simulations typically work by numerically solving the Landau-Lifshitz-Gilbert equation on a discrete space."
    }
];
