import React from "react";
import styled from "styled-components";
import Table from "react-bootstrap/Table";

const Container = styled.div`
    width: 75%;
    padding: 30px 0;
    vertical-align: middle;

    @media only screen and (max-width: 768px) {
        width: 90%;
    }
`;

const Title = styled.div`
    padding: 5px 0;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
    color: #67c1ff;
`;

const StyledTable = styled(Table)`
    table-layout: fixed;
    background-color: rgba(8, 8, 26, 0.75);
`;

const StyledTd = styled.td`
    @media only screen and (max-width: 768px) {
        vertical-align: middle;
        text-align: center;
    }
`;

const IconContainer = styled.div`
    height: 30px;
    width: 75px;
    margin-right: 10px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Icon = styled.img`
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    text-align: center;
`;

const BriefContainer = styled.div`
    padding: 30px;
`;

const BriefHeader = styled.span`
    font-weight: bold;
    display: block;
`;

export default function SkillTable({ variant, title, items, columns }) {
    const clonedItems = items.slice();
    const rows = Math.ceil(items.length / columns);
    const columnMap = [];
    while (clonedItems.length) {
        columnMap.push(clonedItems.splice(0, rows));
    }

    const lastColumn = columnMap[columns - 1];
    const columnMissmatch = columns - lastColumn.length;
    for (let i = 0; i < columnMissmatch; i++) {
        lastColumn.push({ label: "", icon: "" });
    }

    const rowMap = [];
    for (let i = 0; i < rows; i++) {
        const rowItems = [];
        columnMap.forEach((column) => {
            rowItems.push(column[i]);
        });
        rowMap.push(rowItems);
    }

    const IconsTableData = ({ item, index }) => (
        <StyledTd key={index}>
            {
                <>
                    <IconContainer>
                        {item?.icon && <Icon src={item.icon} />}
                    </IconContainer>{" "}
                    {item?.label}
                </>
            }
        </StyledTd>
    );

    const BriefTableData = ({ item, index }) => (
        <td key={index}>
            {
                <BriefContainer>
                    <BriefHeader>{item?.label}</BriefHeader>
                    {item?.brief}
                </BriefContainer>
            }
        </td>
    );

    return (
        <Container>
            <Title>{title}</Title>
            <StyledTable bordered hover variant="dark">
                <tbody>
                    {rowMap.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((item, columnIndex) => (
                                <>
                                    {variant === "icons" && (
                                        <IconsTableData
                                            item={item}
                                            index={columnIndex}
                                        />
                                    )}
                                    {variant === "brief" && (
                                        <BriefTableData
                                            item={item}
                                            index={columnIndex}
                                        />
                                    )}
                                </>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
        </Container>
    );
}

SkillTable.defaultProps = {
    variant: "icons",
    title: "",
    items: [{ label: "", icon: "" }],
    columns: 3
};
