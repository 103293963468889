import React, { useState } from "react";
import styled from "styled-components";
import TabBar from "./TabBar";
import SkillsSection from "./SkillsSection";
import EducationSection from "./EducationSection";
import PublicationsSection from "./PublicationsSection";
import AboutMeSection from "./AboutMeSection";

const StyledSection = styled.section`
    width: 80%;
    height: 100%;
    margin: auto;
    padding: 0 0 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #eeeeee;
    -webkit-box-shadow: 10px -10px 30px 0 rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px -10px 30px 0 rgba(0, 0, 0, 0.75);
    box-shadow: 10px -10px 30px 0 rgba(0, 0, 0, 0.75);

    @media only screen and (max-width: 768px) {
        width: 100%;
    }

    & > * {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: rgba(6, 6, 6, 0.75);
        backdrop-filter: blur(3px);
    }
`;

const Header = styled.h3`
    text-align: center;
    font-size: 2rem;
    padding: 40px 0 30px;
    flex-basis: 100%;
`;

const Container = styled.div`
    border-style: none solid solid solid;
    border-width: 4px;
    border-color: rgb(16, 16, 33);
`;

export default function SectionContainer() {
    const [activeTab, setActiveTab] = useState("skills");

    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };

    const tabs = [
        {
            key: "skills",
            label: "Skills"
        },
        {
            key: "education",
            label: "Education"
        },
        {
            key: "publications",
            label: "Publications"
        },
        {
            key: "about-me",
            label: "About Me"
        }
    ];

    const renderSkills = () => (
        <>
            <Header>Skills</Header>
            <SkillsSection />
        </>
    );

    const renderEducation = () => (
        <>
            <Header>Education</Header>
            <EducationSection />
        </>
    );

    const renderPublications = () => (
        <>
            <Header>Publications</Header>
            <PublicationsSection />
        </>
    );

    const renderAboutMe = () => (
        <>
            <Header>About Me</Header>
            <AboutMeSection />
        </>
    );

    return (
        <StyledSection>
            <TabBar
                tabs={tabs}
                activeTab={activeTab}
                handleSelect={handleSelect}
            />
            <Container>
                {activeTab === "skills" && renderSkills()}
                {activeTab === "education" && renderEducation()}
                {activeTab === "publications" && renderPublications()}
                {activeTab === "about-me" && renderAboutMe()}
            </Container>
        </StyledSection>
    );
}
