import React from "react";
import styled from "styled-components";
import SkillTable from "./SkillTable";
import softwareLanguagesAndFrameworks from "./softwareLanguagesAndFrameworks";
import hardwareAndEmbedded from "./hardwareAndEmbedded";
import photonics from "./photonics";
import nanotechnologyAndMaterialsScience from "./nanotechnologyAndMaterialsScience";

const Footnote = styled.div`
    flex-basis: 100%;
    font-size: 1rem;
    text-align: center;
    transform: translateY(-40px);
`;

export default function SkillsSection() {
    return (
        <>
            <SkillTable
                variant="icons"
                title={"Software Languages and Frameworks"}
                items={softwareLanguagesAndFrameworks}
                columns={3}
            />
            <Footnote>* = used to create this website</Footnote>

            <SkillTable
                variant="icons"
                title={"Hardware and Embedded"}
                items={hardwareAndEmbedded}
                columns={2}
            />

            <SkillTable
                variant="brief"
                title={"Photonics"}
                items={photonics}
                columns={1}
            />

            <SkillTable
                variant="brief"
                title={"Nanotechnology and Materials Science"}
                items={nanotechnologyAndMaterialsScience}
                columns={1}
            />
        </>
    );
}
