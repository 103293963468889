export default [
    {
        label: "Polarization optics",
        brief:
            "Optical polarization refers to the axis of oscillation of the electromagnetic waves. Light is normally unpolarized, but can become polarized by passing through polarizing optical elements, or by being emitted by special light sources such as polarized lasers. The polarization of light provides an interesting degree of freedom which can be utilized in a variety of ways."
    },
    {
        label: "Ultrafast optics",
        brief:
            "Modern ultrafast laser systems can emit incredibly short light pulses, where the duration of a single light pulse is in the femto- and attosecond regime. At such incredibly short time scales, even atomic motion becomes accessible. Ultrafast optics utilizes these short light pulses to study dynamics at unparalleled time scales."
    },
    {
        label: "Nonlinear optics",
        brief:
            "When light propagates through a dielectric medium, the electromagnetic fields induce oscillation of electric dipoles which gives rise to well-known phenomena such as light refraction. When the intensity of the light reaches very high levels, new and exotic phenomena can take place, such as second- third- and higher-harmonic generation, mixing of light frequencies, optical parametric amplification, optical self-focusing or self-defocusing, and generation of terrahertz (THz) radiation. Nonlinear optical phenomena are made accessible thanks to modern high-powered lasere systems."
    },
    {
        label: "Magneto optics",
        brief:
            "When light interacts with a magnetized medium, the polarization and intensity of the light can be affected in interesting ways, and leads to effects like the magneto-optical Kerr effect, the Faraday effect and the Cotton-Mouton effect. This can be utilized in a variety of ways, e.g. to create optical isolators or to investigate the magnetic properties of matter."
    },
    {
        label: "Pump-probe spectroscopy",
        brief:
            "In pump-probe spectroscopy, a strong laser pulse called the pump is used to excite a medium out of equilibrium, while a weaker laser pulse called the probe is used to measure the decay back to thermal equilibrium, which can happen on the order of femtoseconds or picoseconds. Pump-probe spectroscopy allows several of the techniques mentioned above to be used in combination."
    }
];
