import React from "react";
import styled from "styled-components";

const Entry = styled.div`
    width: 90%;
    padding: 20px;
`;

const Media = styled.div`
    padding: 5px 0;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    color: #67c1ff;
`;

const Title = styled.a`
    display: block;
    color: #eeeeee;
`;

const Authors = styled.p`
    display: block;
`;

const Doi = styled.a`
    margin: 10px 0;
    display: block;
    color: #eeeeee;
`;

export default function PublicationSection() {
    const entries = [
        {
            year: "2019",
            media: "Journal of Magnetism and Magnetic Materials. vol. 487",
            authors:
                "Bolstad, Torstein; Lysne, Erik Nikolai; Österberg, Ulf Lennart; Tybell, Per Thomas Martin",
            title:
                "Thickness dependent uniaxial magnetic anisotropy due to step-edges in (111)-oriented La0.7Sr0.3MnO3 thin films",
            ref:
                "https://www.sciencedirect.com/science/article/abs/pii/S0304885318336552?via%3Dihub",
            doi: "https://doi.org/10.1016/j.jmmm.2019.165304",
            doiRef: "https://doi.org/10.1016/j.jmmm.2019.165304"
        },
        {
            year: "2018",
            media: "Journal of Physics: Condensed Matter. vol. 30 (25)",
            authors:
                "Bolstad, Torstein; Lysne, Erik Nikolai; Hallsteinsen, Ingrid; Arenholz, E; Østerberg, Ulf Lennart; Tybell, Per Thomas Martin",
            title:
                "Effect of (111)-oriented strain on the structure and magnetic properties of La2/3Sr1/3MnO3 thin films",
            ref: "https://iopscience.iop.org/article/10.1088/1361-648X/aac468",
            doi: "https://doi.org/10.1088/1361-648X/aac468",
            doiRef: "https://doi.org/10.1088/1361-648X/aac468"
        },
        {
            year: "2017",
            media: "Masters Thesis",
            authors: "Lysne, Erik Nikolai",
            title:
                "Ultrafast Optical and Magneto-Optical Kerr Effect Spectroscopy on Condensed Matter",
            ref: "https://ntnuopen.ntnu.no/ntnu-xmlui/handle/11250/2467024"
        }
    ];
    return (
        <>
            {entries.map((entry, index) => (
                <Entry key={index}>
                    <Media>
                        <b>{entry.year}</b> - {entry.media}
                    </Media>
                    <Authors>{entry.authors}</Authors>
                    <Title href={entry.ref}>{entry.title}</Title>
                    {entry.doi && <Doi href={entry.doiRef}>{entry.doi}</Doi>}
                </Entry>
            ))}
        </>
    );
}
