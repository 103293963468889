import React from "react";
import styled from "styled-components";

const Section = styled.section`
    width: 100%;
    height: auto;
    padding-top: 100px;
    text-align: center;
`;

const HeaderContainer = styled.div`
    width: 100%;
`;

const Header = styled.h1`
    margin: 0 25px;
    padding: 20px 50px 0;
    font-family: "Yeseva One", cursive;
    font-size: 8rem;
    color: #67c1ff;

    @media only screen and (max-width: 768px) {
        padding: 20px 10px 0;
        font-size: 3rem;
    }
`;

const SubHeader = styled.h2`
    margin: 0 50px 20px 50px;
    padding: 10px 20px 20px;
    font-size: 2rem;

    @media only screen and (max-width: 768px) {
        font-size: 1rem;
    }
`;

export default function TitleSection() {
    return (
        <Section>
            <HeaderContainer>
                <Header>Erik Lysne</Header>
                <SubHeader>Online Resume</SubHeader>
            </HeaderContainer>
        </Section>
    );
}
